<template>
  <v-container>
    <v-row
      no-gutters
      justify="space-between"
    >
      <v-col
        sm="4"
        md="4"
      >
        <ViewAsCustomer
          v-if="showViewAsCustomer"
          class="mt-5 ml-2"
        />
      </v-col>
      <v-col
        cols="3"
      >
        <router-link :to="{ name: 'Admin' }">
          <v-btn
            class=" ma-2 mt-6"
            color="success"
          >
            <v-icon
              left
            >
              mdi-home-outline
            </v-icon>Admin Home
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="ml-2">
          <v-card-title>
            Process Order
          </v-card-title>
          <v-card-text>
            <v-icon
              class="mr-2 mb-2 ml-3"
              color="error"
            >
              mdi-clock
            </v-icon>
            <span class="red--text font-weight-light">Process Order Screen Last Updated: {{ currentDateTime }}</span>
            <v-btn
              class="ml-5 mb-2"
              small
              color="success"
              @click="getOrders()"
            >
              <v-icon
                left
              >
                mdi-refresh
              </v-icon>Refresh Grid
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="ml-2">
          <v-row
            no-gutters
            justify="space-between"
          >
            <v-col
              sm="5"
              md="5"
            >
              <v-card-title class="ml-2">
                Search Results
              </v-card-title>
            </v-col>
            <v-col
              cols="3"
              class="pr-6"
              sm="3"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            item-key="importCode"
            class="mt-10"
            :headers="processOrderHeaders"
            :items="orderResults"
            :search="search"
            :loading="resultsLoading"
          >
            <template v-slot:[`item.process`]="{ item }">
              <v-btn
                v-if="item.recordCount > 0"
                small
                color="primary"
              >
                Process
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { nowwService } from '@/shared/services'

export default {
  name: 'ProcessOrder',
  components: {
    ViewAsCustomer: () =>
      import('@/views/pages/components/user-state/ViewAsCustomer')
  },

  data () {
    return {
      search: '',
      currentDateTime: '',
      selected: [],
      orderResults: [],
      resultsLoading: false,

      // Change value names to proper value names returned from API call
      processOrderHeaders: [
        {
          text: 'Process',
          align: 'start',
          value: 'process'
        },
        { text: 'File Type', value: 'fileType', sortable: true },
        { text: 'Order Type', value: 'orderType', align: 'start' },
        { text: 'Scheduled Date', value: 'scheduledDate', sortable: true },
        { text: 'Job Number', value: 'jobNumber', align: 'center', sortable: false },
        { text: 'CSR', value: 'csr', sortable: false, align: 'center' },
        { text: 'Order Date', value: 'parsedOrderDate', sortable: false, align: 'center' },
        { text: 'Record Count', value: 'recordCount', sortable: false, align: 'center' },
        { text: 'Customer', value: 'customerName', sortable: false, align: 'center' }
      ]
    }
  },

  created () {
    this.getOrders()
  },

  computed: {
    ...mapGetters(['viewAsCustomer', 'simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId'
    ]),
    showViewAsCustomer () {
      return this.$store.getters['auth/isMMSRole']()
    }
  },

  mounted () {
    this.updateDateTime()
  },

  methods: {
    updateDateTime () {
      this.currentDateTime = new Date().toLocaleString()
    },
    async getOrders () {
      this.resultsLoading = true
      await nowwService.getOrders()
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.orderResults = resp
            this.resultsLoading = false
          }
        })
    }
  }
}
</script>
